/**
 * Eu cookie law
 *
 * @author Mishal.cz <mishal@mishal.cz>
 */

(function ($, window, document) {

  // default options
  var defaultOptions = {
    cookieName: 'eu-cookie-law',
    message: 'Tento web používá k analýze návštěvnosti soubory cookie.',
    agreeMessage: 'Souhlasím',
    moreInfoMessage: 'Více informací',
    cookieDomain: undefined
  };

  var insertMessage = function () {
    var
      $div = $('<div class="eu-cookie-law">' + this.options.message + '</div><noscript><style>.eu-cookie-law { display: none; }</style></noscript>'),
      $button = $('<button type="button" class="btn">' + this.options.agreeMessage + '</button>'),
      style = '.eu-cookie-law {' +
        'position: fixed;' +
        'left: 0;' +
        'top: 0;' +
        'text-align: center;' +
        'padding: 1em;' +
        'width: 100%;' +
        'color: white;' +
        'background-color: black;' +
        'z-index: 1100;' +
        '}' +
        '.eu-cookie-law button {' +
        'background: green;' +
        'color: white;' +
        'margin: 0 0.5em;' +
        '}' +
        '.eu-cookie-law a {' +
        'color: white;' +
        '}'
      ,
      $style = $('<style>' + style + '</style>'),
      $moreInfoLink = $('<a class="btn btn-mini" href="https://www.google.com/policies/technologies/cookies/">' + this.options.moreInfoMessage + '</a>');

    var cookieName = this.options.cookieName,
      cookieDomain = this.options.cookieDomain ? this.options.cookieDomain : null;

    $button.on('click', function (e) {
      e.preventDefault();
      var date = new Date();
      date.setFullYear(date.getFullYear() + 10);
      var cookie = cookieName + '=1; path=/;';
      if (cookieDomain) {
        cookie += ' domain=' + cookieDomain + ';';
      }
      cookie += 'expires=' + date.toGMTString();
      document.cookie = cookie;
      $div.hide();
    });

    $div.append($button);
    $div.append($moreInfoLink);

    $('body').append($div);
    $('head').append($style);
  };

  function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  };

  var EUCookieLaw = function (options) {
    this.options = $.extend(true, defaultOptions, options);

    var self = this;
    $(document).ready(function () {
      if (!getCookie(self.options.cookieName)) {
        $.proxy(insertMessage, self)();
      }
    });
  };

  EUCookieLaw.prototype = {
    constructor: EUCookieLaw,
    isCookieUsageAllowed: function () {
      var cookie = getCookie(this.options.cookieName);
      return Boolean(cookie);
    },
    isCookieUsageForbidden: function () {
      return !this.isCookieUsageAllowed();
    }
  };

  var options = {};
  if (typeof window.EUCookieLawOptions === 'object') {
    options = window.EUCookieLawOptions;
  }

  // export to window
  window.EUCookieLaw = new EUCookieLaw(options);

})(jQuery, window, document);
